// import { useWindowResize } from "hooks/useWindowResize";
import React from "react";
import { Form } from "react-bootstrap";
import { Label } from "../utils";
import SVG from "react-inlinesvg";
import styles from "./styles.module.scss";

const SelectInput = ({
  defaultSelectValue,
  currentValue,
  onChangeHandler,
  label,
  options,
  size,
  value,
  disable,
  ...props
}) => {
  const renderOptions = (options) => {
    return options.map((item, index) => (
      <option key={index} label={item} value={item} defaultValue={item === value} >{item}</option>
    ));
  };

  return (
    <div className={`${styles.rst__clr} selectInput position-relative`}>
      {/* <Form.Select className={`${styles.select} ${props.className}`} aria-label={defaultSelectValue}>
                <option>--- Select ---</option>
                {renderOptions}
            </Form.Select> */}
      {/* 
      <div className={`d-flex`}>
        <Label>{label}</Label>
        {props.seconLabel && (
          <Label className={`${styles.labeltwo} ml-2`} htmlFor={props.id}>
            ({props.seconLabel})
          </Label>
        )}
      </div> */}

      <Form.Control
        value={currentValue}
        onChange={(e) => onChangeHandler(e.target.value)}
        className={`Inputstyle ${styles.select} ${props.className}`}
        label={`--- Select ---`}
        as="select"
        disabled={disable}
        size={size ?? "lg"}
      // custom
      >
        <option value="" key={-1}>
          --- Select ---
        </option>
        {renderOptions(options, value)}
      </Form.Control>
      <SVG
        src="/assets/vectors/Vector_right_arror.svg"
        width="16px"
        height="16px"
        className={`${styles.selectIcon}`}
      />
    </div>
  );
};
export default SelectInput;
